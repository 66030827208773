@charset "UTF-8";

@use '~node_modules/include-media/dist/include-media' as responsive;

.u-m-0 {
        margin-bottom: 0 !important;
}

.u-outer-left {
        @include responsive.media('>way-to-large') {
                margin-left: -30rem;
        }
        @include responsive.media('>tablet', '<=way-to-large') {
                margin-left: calc(50% - 50vw);
        }
}

.u-outer-right {
        @include responsive.media('>way-to-large') {
                margin-right: -30rem;
        }
        @include responsive.media('>tablet', '<=way-to-large') {
                margin-right: calc(50% - 50vw);
        }
}

.u-p-right {
        padding-right: 0;
        @include responsive.media('>desktop') {
                padding-right: 6rem;
        }

        @include responsive.media('>tablet', '<=desktop') {
                padding-right: 6rem;
        }

        @include responsive.media('>phone', '<=tablet') {
                padding-right: 0rem;
        }
}

.u-p-left {
        padding-left: 0;
        @include responsive.media('>desktop') {
                padding-left: 6rem;
        }

        @include responsive.media('>tablet', '<=desktop') {
                padding-left: 6rem;
        }

        @include responsive.media('>phone', '<=tablet') {
                padding-left: 0rem;
        }
}

.u-p-right-half {
        padding-right: 0;
        @include responsive.media('>desktop') {
                padding-right: 3rem;
        }

        @include responsive.media('>tablet', '<=desktop') {
                padding-right: 3rem;
        }

        @include responsive.media('>phone', '<=tablet') {
                padding-right: 0rem;
        }
}

.u-p-left-half {
        padding-left: 0;
        @include responsive.media('>desktop') {
                padding-left: 3rem;
        }

        @include responsive.media('>tablet', '<=desktop') {
                padding-left: 3rem;
        }

        @include responsive.media('>phone', '<=tablet') {
                padding-left: 0rem;
        }
}

.u-top-line {
        border-top: 1px solid var(--separator-color);
}

@for $index from 1 to 11 {
        .u-flex-#{$index} {
                flex: $index * 10%;

                @include responsive.media('<=tablet') {
                        flex: 100%;
                }
        }
}

.u-phone-d-none {
        @include responsive.media('<=phone') {
                display: none !important;
        }
}

.u-tablet-d-none {
        @include responsive.media('>phone', '<=tablet') {
                display: none !important;
        }
}

.u-desktop-d-none {
        @include responsive.media('>tablet') {
                display: none !important;
        }
}

.u-m-bottom {
        margin-bottom: 4rem;
}
