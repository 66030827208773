@charset "UTF-8";

@use '~node_modules/include-media/dist/include-media' as responsive;

.video {

        iframe {
                width: 100%;
                height: 35rem;

                @include responsive.media('>phone','<=desktop') {
                        height: 20rem;
                }
                @include responsive.media('<=phone') {
                        height: 15rem;
                }
        }

}
