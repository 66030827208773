@charset "UTF-8";


$colors: (
	'body-background-color': white,
	'text-normal-color': #1b458c,
	'text-hover-color': #589d42,
	'text-light-color': #2E2E2E,
	'link-hover-color': #589d42,
	'button-background-color': #589d42,
	'button-hover-background-color': #6ea75b,
	'menu-background-color': white,
	'box-shadow-color': #CBCBCB,
	'footer-background-color': #E8ECF4,
	'step-background-color': #004b8a,
	'separator-color': #C4E0E5,
	'bullet-point-color': #589d42,
);

$breakpoints: (
	'phone': 414px,
	'tablet': 834px,
	'desktop': 1024px,
	'way-to-large': 1800px,
);

$levels: (
	'level-1': 10,
	'level-2': 100,
	'level-3': 1000,
	'level-4': 5000,
);

$variables: map-merge(
	$colors,
	map-merge(
                $breakpoints,
                $levels
	)
);

:root {
	@each $name, $color in $variables {
		--#{$name}: #{$color};
	}
}
