@charset "UTF-8";

footer {
        background-color: var(--footer-background-color);
        color: var(--text-light-color);
        line-height: 2;
        a {
                color: inherit;
        }
}
