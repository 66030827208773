@charset "UTF-8";

@use '../utils/variables' as vars;
@use '~node_modules/include-media/dist/include-media' as responsive;

$phone: map-get(vars.$breakpoints, 'phone');
$tablet: map-get(vars.$breakpoints, 'tablet');
$desktop: map-get(vars.$breakpoints, 'desktop');

.container {
        max-width: calc(100% - 2rem);
        margin: 0 auto;

        @include responsive.media('>phone', '<=tablet') {
                max-width: calc(#{$phone} - 4rem);
        }

        @include responsive.media('>tablet', '<=desktop') {
                max-width: 14rem;
                max-width: ($tablet * 4 / 5);
        }

        @include responsive.media('>desktop') {
                max-width: ($desktop * 4 / 5);
        }
}
