@charset "UTF-8";

@use '../../node_modules/jbox/src/scss/jBox.scss';
@use '../../node_modules/jbox/src/scss/plugins/jBox.Image.scss' as JboxImage;

@use '~node_modules/include-media/dist/include-media' as responsive;

.gallery {

        width: 100%;

        &__wrapper {
                display: block;
                white-space: nowrap;
                letter-spacing: -3px;
                overflow-x: auto;
                overflow-y: hidden;
        }

        &__image {
                padding: .5rem;
                border-left: 1px solid var(--text-normal-color);
                border-top: 1px solid var(--text-normal-color);
                border-bottom: 1px solid var(--text-normal-color);
                cursor: pointer;
                line-height: 0;
                display: inline-block;
        }

        &__image:last-child {
                border-right: 1px solid var(--text-normal-color);
        }

        img {
                line-height: 0;
                max-height: 18rem;
                display: inline-block;
                transform-origin: center;
                line-height: 0;
                transition: 200ms transform ease-out;

                @include responsive.media('<=tablet') {
                        max-height: 15rem;
                }
        }

        &__image:hover {
                z-index: 9999;
                img {
                        transform: scale(1.05);
                }
        }

        // &.open {
        //         position: fixed;
        //         top: 0;
        //         left: 0;
        //         right: 0;
        //         bottom: 0;
        //         z-index: 9999;
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        // }

        // &.open::before {
        //         content: '';
        //         position: absolute;
        //         top: 0;
        //         left: 0;
        //         right: 0;
        //         bottom: 0;
        //         z-index: -1;
        //         background-color: rgba(0, 0 ,0 , .5);
        // }

        // &.open &__wrapper {
        //         display: block;
        //         white-space: nowrap;
        //         overflow-x: auto;
        //         overflow-y: hidden;
        //         letter-spacing: -3px;
        // }

        // &.open &__image {
        //         display: inline-block;
        //         // height: 50vh;
        //         // overflow: auto;
        // }

        // &.open &__image {
        //         border: none;
        // }

        // &.open &__image img {
        //         max-height: 100% !important;
        // }

        // &.open &__image:hover img {
        //         transform: none;
        // }
}
