@charset "UTF-8";

@use '~node_modules/include-media/dist/include-media' as responsive;

.primary-header {
        // position: sticky;
        position: relative;
        top: 0;
        padding: 1rem 0;
        margin-bottom: 4rem;
        margin-top: 2rem;
        z-index: 9999;
        background-color: white;

        @include responsive.media('<=tablet') {
                margin-bottom: 0rem;
                margin-top: 1rem;
                color: white;
        }

        &::before {
                @include responsive.media('<=tablet') {
                        position: absolute;
                        left: -2rem;
                        top: -10rem;
                        bottom: 0rem;
                        transform: rotate(-2deg);
                        content: '';
                        width: calc(100% + 5rem);
                        display: block;
                        background-color: var(--text-normal-color);
                        z-index: -1;
                }
        }

        & > * {
                display: flex;
                justify-content: space-between;
                align-items: center;
        }
}
