@charset "UTF-8";

@use 'sass:math';
@use '../utils/variables' as vars;
@use '~node_modules/include-media/dist/include-media' as responsive;

$phone: map-get(vars.$breakpoints, 'phone');
$tablet: map-get(vars.$breakpoints, 'tablet');
$desktop: map-get(vars.$breakpoints, 'desktop');

.image-group {
        position: relative;
        text-align: right;

        img {
                display: inline-block;
                line-height: 0;
                margin: 0;
                max-width: 100%;
        }

        &__a {
                padding-bottom: .3rem;
                @include responsive.media('<tablet') {
                        display: none !important;
                }
        }

        &__b {
                @include responsive.media('<tablet') {
                        display: none !important;
                }
        }

        &__c {
                position: absolute;
                top: 25%;
                left: -6rem;
                width: 80%;
                border: .5rem solid white;
                border-top-right-radius: 50%;

                @include responsive.media('<tablet') {
                        display: none !important;
                }
        }
}
