@charset "UTF-8";

.button {
        display: inline-block;
        padding: 1rem;
        border-radius: 10rem;
        min-width: 10rem;
        text-align: center;
        color: white;
        text-decoration: none;
        background-color: var(--button-background-color);
        transition: all 400ms;

        &:visited,
        &:link,
        &:active,
        &:focus {
                color: white !important;
        }

        &:hover {
                color: white !important;
                background-color: var(--button-hover-background-color);
        }
}
