// jBox plugin: Image

.jBox-Image {
  .jBox-container {
    background-color: transparent;
  }

  .jBox-content {
    padding: 0;
    width: 100%;
    height: 100%;
  }
}

.jBox-image-container {
  background: center center no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.jBox-image-label-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 40px;
  z-index: 100;
  display: flex;
}

.jBox-image-label-container {
  position: relative;
  flex: 1;
}

.jBox-image-label {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 8px 12px;
  font-size: 15px;
  line-height: 24px;
  transition: opacity .36s;
  opacity: 0;
  z-index: 0;
  pointer-events: none;

  &.expanded {
    background: #000;
  }

  &:not(.expanded) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  @media (max-width: 600px) {
    font-size: 13px;
  }
}

.jBox-image-pointer-next,
.jBox-image-pointer-prev {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: .8;
  transition: opacity .2s;
  background: no-repeat center center url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ijc0LjcgMjI0IDE4LjcgMzIiPg0KPHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iTTkzLDIyNy40TDgwLjQsMjQwTDkzLDI1Mi42YzAuNCwwLjQsMC40LDEuMSwwLDEuNWwtMS42LDEuNmMtMC40LDAuNC0xLDAuNS0xLjUsMEw3NSwyNDAuN2MtMC40LTAuNC0wLjUtMSwwLTEuNWwxNC45LTE0LjljMC40LTAuNCwxLTAuNCwxLjUsMGwxLjYsMS42QzkzLjUsMjI2LjQsOTMuNCwyMjcsOTMsMjI3LjR6Ii8+DQo8L3N2Zz4=);
  background-size: 11px auto;
  user-select: none;
  z-index: 1;

  &:hover {
    opacity: 1;
  }
}

.jBox-image-pointer-next {
  transform: scaleX(-1);
}

.jBox-image-counter-container {
  flex-shrink: 0;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: #fff;
  text-align: right;
  display: none;
}

.jBox-image-has-counter .jBox-image-counter-container {
  display: block;
}

.jBox-overlay.jBox-overlay-Image {
  background: #000;
}

.jBox-image-not-found {
  background: #000;

  &:before {
    content: '';
    box-sizing: border-box;
    display: block;
    width: 80px;
    height: 80px;
    margin-top: -40px;
    margin-left: -40px;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 5px solid #222;
    border-radius: 50%;
  }

  &:after {
    content: '';
    display: block;
    box-sizing: content-box;
    z-index: auto;
    width: 6px;
    height: 74px;
    margin-top: -37px;
    margin-left: -3px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #222;
    transform: rotateZ(45deg);
    transform-origin: 50% 50% 0;
  }
}

// Download button

.jBox-image-download-button-wrapper {
  position: absolute;
  top: -40px;
  right: 35px;
  height: 40px;
  display: flex;
  cursor: pointer;
  opacity: .8;
  transition: opacity .2s;

  &:hover {
    opacity: 1;
  }
}

.jBox-image-download-button-icon {
  width: 40px;
  height: 40px;
  background: center center no-repeat url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNjQwIj48cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNNDE2IDI1NnYtMTkyaC0xOTJ2MTkyaC0xNjBsMjU2IDI1NiAyNTYtMjU2aC0xNjB6TTAgNTc2aDY0MHY2NGgtNjQwdi02NHoiPjwvcGF0aD48L3N2Zz4=);
  background-size: 60%;
}

.jBox-image-download-button-text {
  white-space: nowrap;
  line-height: 40px;
  padding: 0 10px 0 0;
  color: #fff;
  font-size: 14px;
}

// Image spinner

@keyframes jBoxImageLoading {
  to {
    transform: rotate(360deg);
  }
}

.jBox-image-loading:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  margin-left: -16px;
  border: 4px solid #333;
  border-bottom-color: #666;
  animation: jBoxImageLoading 1.2s linear infinite;
  border-radius: 50%;
}
