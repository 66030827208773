@charset "UTF-8";

@use '~node_modules/include-media/dist/include-media' as responsive;

.block {
        display: flex;
        align-items: center;

        @include responsive.media('<tablet') {
                // display: block;
                flex-wrap: wrap;
        }

        &.reverse {
                @include responsive.media('<tablet') {
                        flex-direction: column-reverse;
                }
        }

        & > * {
                @include responsive.media('<tablet') {
                        width: 100%;
                }
        }

        &__content h1,
        &__content h2,
        &__content h3,
        &__content p {
                margin: 0;
        }

        &__content h1 {
                font-size: 2.5rem;
                font-weight: 700;
                margin-bottom: 1.875rem;
        }

        &__content h2 {
                font-size: 2;
                font-weight: 700;
                margin-bottom: 1.875rem;
        }

        &__content h3 {
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: .3125rem;
                color: var(--text-hover-color);
        }

        &__content h4 {
                font-size: 1rem;
                font-weight: 700;
                margin-bottom: .3125rem;
        }

        &__content p {
                margin-bottom: 2rem;
        }

        &__content p,
        &__content a,
        &__content li,
        &__content td {
                line-height: 1.7;
                font-size: 1.1rem;

                @include responsive.media('>phone', '<=tablet') {
                        font-size: 1rem;
                }

                @include responsive.media('<=phone') {
                        font-size: 1rem;
                }
        }

        &__content .button {
                margin-bottom: 2rem;
        }

        &__image img {
                max-width: 100%;
                @include responsive.media('<tablet') {
                        display: block;
                        padding: 2rem;
                        max-width: 20rem;
                        margin: 0 auto;
                        border: 1px solid var(--step-background-color);
                }
        }

        &__content td {
                padding-right: 2rem;

                @include responsive.media('<tablet') {
                        padding-block: .5em;
                }
        }

        &__content td:first-child {
                white-space: nowrap;
        }
}
