@charset "UTF-8";


.branding {

        &__logo {
                width: 10rem;
        }

        img {
                max-width: 100%;
        }
}
