@charset "UTF-8";

ul.standard-list {
        padding-left: .5em;
        list-style: none;
        margin-bottom: 2rem;

        & > li {
                position: relative;
                padding-left: 1.5rem;
                margin-bottom: 1rem;
                line-height: 1.2;
        }

        & > li > * {
                line-height: 1;
        }

        & > li::before {
                position: absolute;
                left: 0;
                top: .3em;
                $size: .5em;
                content: '';
                display: inline-block;
                width: $size;
                height: $size;
                background-color: var(--bullet-point-color);
                border-radius: 2px;
                margin-right: 1em;
                line-height: inherit;
        }
}
