@charset "UTF-8";

@use '~node_modules/include-media/dist/include-media' as responsive;

.menu {
        padding: 0;
        margin: 0;
        list-style: none;
        list-style-type: none;

        &__item a {
                text-decoration: none;
                display: inline-block;
                padding: .5em;
                line-height: 1.4;
                color: inherit;
        }

        @include responsive.media('<=tablet') {
                font-size: .8rem;
        }

        @include responsive.media('>tablet') {

                background-color: var(--menu-background-color);
                border-radius: 10rem;
                padding: 1rem;
                display: inline-block;
                box-shadow: 0 0 1rem var(--box-shadow-color);

                &__item {
                        display: inline-block;
                        transition: color 400ms;
                }

                &__item:hover {
                        color: var(--text-hover-color);
                }
        }
}
