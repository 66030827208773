@charset "UTF-8";

*,
*::after,
*::before {
        box-sizing: inherit;
}

.page {
        max-width: 100vw;
        overflow-x: hidden;
}

body {
        background-color: var(--body-background-color);
        box-sizing: border-box;
}

img {
        max-width: 100%;
}

html {
        scroll-behavior: smooth;
}

a {
        &:link,
        &:visited,
        &:active,
        &:focus {
                color: var(--text-normal-color);
                text-decoration: none;
                outline: none;
                font-size: inherit;
                line-height: inherit;
                display: inline-block;
        }

        &:hover {
                color: var(--link-hover-color);
        }
}
