// Global

.jBox-wrapper {
  text-align: left;
  box-sizing: border-box;
}

.jBox-title,
.jBox-content,
.jBox-container {
  position: relative;
  word-break: break-word;
  box-sizing: border-box;
}

.jBox-container {
  background: #fff;
}

.jBox-content {
  padding: 8px 12px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity .2s;
}

.jBox-footer {
  box-sizing: border-box;
}

// jBox Tooltip

.jBox-Tooltip,
.jBox-Mouse {
  .jBox-container {
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .25);
  }

  .jBox-title {
    padding: 8px 10px 0;
    font-weight: bold;
  }

  &.jBox-hasTitle .jBox-content {
    padding-top: 5px;
  }
}

.jBox-Mouse {
  pointer-events: none;
}

// Pointer

.jBox-pointer {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    background: #fff;
    transform: rotate(45deg);
    box-sizing: border-box;
  }

  &-top {
    top: 0;

    &:after {
      left: 5px;
      top: 6px;
      box-shadow: -1px -1px 2px rgba(0, 0, 0, .15);
    }
  }

  &-right {
    right: 0;

    &:after {
      top: 5px;
      right: 6px;
      box-shadow: 1px -1px 2px rgba(0, 0, 0, .15);
    }
  }

  &-left {
    left: 0;

    &:after {
      top: 5px;
      left: 6px;
      box-shadow: -1px 1px 2px rgba(0, 0, 0, .15);
    }
  }

  &-bottom {
    bottom: 0;

    &:after {
      left: 5px;
      bottom: 6px;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, .15);
    }
  }

  &-top,
  &-bottom {
    width: 30px;
    height: 12px;
  }

  &-left,
  &-right {
    width: 12px;
    height: 30px;
  }
}

// jBox Modal

.jBox-Modal {
  .jBox-container {
    border-radius: 4px;
  }

  .jBox-container,
  &.jBox-closeButton-box:before {
    box-shadow: 0 3px 15px rgba(0, 0, 0, .4), 0 0 5px rgba(0, 0, 0, .4);
  }

  .jBox-content {
    padding: 15px 20px;
  }

  .jBox-title {
    border-radius: 4px 4px 0 0;
    padding: 15px 20px;
    background: #fafafa;
    border-bottom: 1px solid #eee;
  }

  &.jBox-closeButton-title {
    .jBox-title {
      padding-right: 65px;
    }
  }

  .jBox-footer {
    border-radius: 0 0 4px 4px;
  }
}

// Close button

.jBox-closeButton {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  box-sizing: border-box;

  svg {
    position: absolute;
    top: 50%;
    right: 50%;
  }

  path {
    fill: #aaa;
    transition: fill .2s;
  }

  &:hover {
    path {
      fill: #888;
    }
  }
}

// Close button in overlay

.jBox-overlay {
  .jBox-closeButton {
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-right: -10px;
    }

    path {
      fill: #ddd;
    }

    &:hover path {
      fill: #fff;
    }
  }

}

// Close button in title

.jBox-closeButton-title {
  .jBox-closeButton {
    top: 0;
    right: 0;
    bottom: 0;
    width: 50px;
  }

  svg {
    width: 12px;
    height: 12px;
    margin-top: -6px;
    margin-right: -6px;
  }
}

// Close button in box

.jBox-closeButton-box {
  box-sizing: border-box;

  .jBox-closeButton {
    top: -8px;
    right: -10px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;

    svg {
      width: 10px;
      height: 10px;
      margin-top: -5px;
      margin-right: -5px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    right: -10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
  }

  &.jBox-pointerPosition-top:before {
    top: 5px;
  }

  &.jBox-pointerPosition-right:before {
    right: 2px;
  }
}


.jBox-Modal.jBox-hasTitle.jBox-closeButton-box .jBox-closeButton {
  background: #fafafa;
}

// Overlay

.jBox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);
}

// Footer

.jBox-footer {
  background: #fafafa;
  border-top: 1px solid #eee;
  padding: 8px 10px;
  border-radius: 0 0 3px 3px;
}

// Block scrolling

body[class^="jBox-blockScroll-"],
body[class*=" jBox-blockScroll-"] {
  overflow: hidden;
}

// Draggable

.jBox-draggable {
  cursor: move;
}

// Spinner

@keyframes jBoxLoading {
  to {
    transform: rotate(360deg);
  }
}

.jBox-loading .jBox-content {
  opacity: .2;
}

.jBox-loading-spinner .jBox-content {
  min-height: 38px !important;
  min-width: 38px !important;
  opacity: 0;
}

.jBox-spinner {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;

  &:before {
    display: block;
    box-sizing: border-box;
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, .2);
    border-top-color: rgba(0, 0, 0, .8);
    animation: jBoxLoading .6s linear infinite;
  }
}

// Countdown

.jBox-countdown {
  border-radius: 4px 4px 0 0;
  z-index: 0;
  background: #000;
  opacity: .2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  overflow: hidden;

  &-inner {
    top: 0;
    right: 0;
    width: 100%;
    height: 3px;
    position: absolute;
    background: #fff;
  }
}

// Animations

[class^="jBox-animated-"],
[class*=" jBox-animated-"] {
  animation-fill-mode: both;
}

// Animation tada

@keyframes jBox-tada {
  0% {
    transform: scale(1);
  }

  10%,
  20% {
    transform: scale(0.8) rotate(-4deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.2) rotate(4deg);
  }

  40%,
  60%,
  80% {
    transform: scale(1.2) rotate(-4deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

.jBox-animated-tada {
  animation: jBox-tada 1s;
}

// Animation tadaSmall

@keyframes jBox-tadaSmall {
  0% {
    transform: scale(1);
  }

  10%,
  20% {
    transform: scale(0.9) rotate(-2deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(2deg);
  }

  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-2deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

.jBox-animated-tadaSmall {
  animation: jBox-tadaSmall 1s;
}

// Animation flash

@keyframes jBox-flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.jBox-animated-flash {
  animation: jBox-flash .5s;
}

// Animation shake

@keyframes jBox-shake {

  0%,
  100% {
    transform: translateX(0);
  }

  20%,
  60% {
    transform: translateX(-6px);
  }

  40%,
  80% {
    transform: translateX(6px);
  }
}

.jBox-animated-shake {
  animation: jBox-shake .4s;
}

// Animation pulseUp

@keyframes jBox-pulseUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

.jBox-animated-pulseUp {
  animation: jBox-pulseUp .25s;
}

// Animation pulseDown

@keyframes jBox-pulseDown {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.85);
  }

  100% {
    transform: scale(1);
  }
}

.jBox-animated-pulseDown {
  animation: jBox-pulseDown .25s;
}

// Animation popIn

@keyframes jBox-popIn {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.jBox-animated-popIn {
  animation: jBox-popIn .25s;
}

// Animation popOut

@keyframes jBox-popOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0);
  }
}

.jBox-animated-popOut {
  animation: jBox-popOut .25s;
}

// Animation fadeIn

@keyframes jBox-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.jBox-animated-fadeIn {
  animation: jBox-fadeIn .2s;
}

// Animation fadeOut

@keyframes jBox-fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.jBox-animated-fadeOut {
  animation: jBox-fadeOut .2s;
}

// Animation slideUp

@keyframes jBox-slideUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-300px);
    opacity: 0;
  }
}

.jBox-animated-slideUp {
  animation: jBox-slideUp .4s;
}

// Animation slideRight

@keyframes jBox-slideRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(300px);
    opacity: 0;
  }
}

.jBox-animated-slideRight {
  animation: jBox-slideRight .4s;
}

// Animation slideDown

@keyframes jBox-slideDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(300px);
    opacity: 0;
  }
}

.jBox-animated-slideDown {
  animation: jBox-slideDown .4s;
}

// Animation slideLeft

@keyframes jBox-slideLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-300px);
    opacity: 0;
  }
}

.jBox-animated-slideLeft {
  animation: jBox-slideLeft .4s;
}
