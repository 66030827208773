@charset "UTF-8";

@use '~node_modules/include-media/dist/include-media' as responsive;

body {
        font-family: 'Poppins', sans-serif;
        font-size: 70%;
        color: var(--text-normal-color);

        @include responsive.media('>phone', '<=tablet') {
                font-size: 80%;
        }

        @include responsive.media('>tablet', '<=desktop') {
                font-size: 90%;
        }

        @include responsive.media('>desktop') {
                font-size: 100%;
        }
}
