@charset "UTF-8";

@use '~node_modules/include-media/dist/include-media' as responsive;

.steps {
        list-style: none;
        counter-reset: steps;
        padding: 0;

        li {
                position: relative;
                counter-increment: steps;
                margin-bottom: 2rem;
                padding-left: 4rem;
        }

        li::before {
                line-height: 1.2;
                position: absolute;
                left: 0;
                top: .2em;
                content: counter(steps);
                background-color: white;
                border: 1px solid var(--step-background-color);
                color: var(--step-background-color);
                font-weight: 600;
                // font-size: 1.2rem;
                width: 2rem;
                display: inline-block;
                text-align: center;
                padding: .3rem;
                border-radius: 5px;
                margin-right: 1rem;

                @include responsive.media('>phone', '<=tablet') {
                        // font-size: 1.2rem;
                        width: 2rem;
                        margin-right: .5rem;
                }

                @include responsive.media('>tablet', '<=desktop') {
                        // font-size: 1.4rem;
                        width: 1.4rem;
                }
        }

        li.active::before {
                background-color: var(--step-background-color);
                color: white;
        }
}
