@charset "UTF-8";

@use '~node_modules/include-media/dist/include-media' as responsive;

section.main-section {
        padding-bottom: 4rem;
        margin-bottom: 4rem;
        position: relative;

        @include responsive.media('<=tablet') {
                margin-bottom: 0rem;
        }

        @include responsive.media('>tablet') {
                /**
                        Szekció végi zöld vonal
                */
                &:not(:last-child)::before,
                &:not(:last-child)::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%, 50%);
                }

                &:not(:last-child)::after {
                        width: 1rem;
                        height: 1rem;
                        border-radius: 2px;
                        background-color: var(--bullet-point-color);
                        box-shadow: 0 0 0 1rem white;
                }

                &:not(:last-child)::before {
                        width: 50%;
                        transform: translate(-50%);
                        border-bottom: 1px solid var(--bullet-point-color);
                }
        }
}

section.footer-section  {
        padding: 2rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .branding {
                padding-right: 4rem;
        }
}
